import { useState, useMemo, FC, useCallback, Component } from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useResizeObserver from "use-resize-observer";
import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  LineChart as RechartsLineChart,
} from "recharts";

import styles from "./LineChart.module.scss";
import { useModal } from "src/hooks";
import { GRAY } from "src/constants/colors";
import { selectTrackersEntities } from "src/store/selectors";
import { CreateDashboardDateRangeModal } from "src/features";
import {
  updateQueryParams,
  formatToMonthFullYearDate,
  formatToMonthDayFullYearDate,
} from "src/utils";

// Inner imports
import * as hooks from "./hooks";
import * as utils from "./utils";
import * as constants from "./constants";
import {
  Labels,
  CustomTooltip,
  LineChartEvent,
  ForecastDivider,
  LineChartSettings,
} from "./components";
import type {
  Axis,
  ChartItem,
  LineChartProps,
  FormattedChartItem,
  LineChartSelection,
} from "./types";

export const LineChart: FC<LineChartProps> = ({
  style,
  className = "",
  trackerIds = [],
  data: rawData,
  trackersCollectionId = "",
  formatDateHandler: initialFormatDateHandler = formatToMonthFullYearDate,
  chartStyles: { graphHeight, lineStrokeWidth = 2.5 } = {},
  chartSettings: {
    hasTrendLine = false,
    isTooltipOverflowAvailable = false,
  } = {},
  axis: {
    yAxisValueFormatter,
    yAxisAdditionalValueFormatter,
    yAxisVerticalPadding = { top: 0, bottom: 0 },
    increaseYAxisWidthInPercentage = 0,
  } = {},
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const { setModal } = useModal();

  const { ref: chartWrapperRef, width: chartWidth = 0 } =
    useResizeObserver<HTMLDivElement>();

  const trackersEntities = useSelector(selectTrackersEntities);

  const [yAxisWidth, setYAxisWidth] = useState<number>(
    constants.LINE_CHART_Y_AXIS_DEFAULT_WIDTH,
  );

  const { dashboardDateRange, dashboardDateRangeId } =
    hooks.useLineChartDashboardDateRanges();

  const { data: preparedData, forecastStartDate } =
    hooks.usePreparedLineChartData({ data: rawData, dashboardDateRange });

  const {
    events,
    onEventClick,
    selectedEvent,
    renderedEvents,
    selectedEventId,
  } = hooks.useLineChartEvents({
    data: preparedData,
    trackersCollectionId,
    dashboardDateRangeId,
  });

  const formatDateHandler = useMemo<(value: number) => string>(() => {
    if (dashboardDateRange?.category === "custom")
      return formatToMonthDayFullYearDate;

    return initialFormatDateHandler;
  }, [dashboardDateRange?.category, initialFormatDateHandler]);

  const hasForecast = useMemo<boolean>(
    () => Boolean(forecastStartDate),
    [forecastStartDate],
  );

  const data = useMemo<ChartItem[]>(() => {
    const extendedData = utils.getExtendedData({
      events,
      data: preparedData,
      dashboardDateRange,
    });

    if (dashboardDateRange)
      return utils.getDataFilteredWithRange({
        data: extendedData,
        endDate: dashboardDateRange.endDate,
        startDate: dashboardDateRange.startDate,
      });

    return extendedData;
  }, [events, preparedData, dashboardDateRange]);

  const {
    forecastLineAnimationDelay,
    isInitialAnimationFinished,
    setIsActualDataLineAnimationFinished,
    setIsForecastDataLineAnimationFinished,
  } = hooks.useLineAnimationConfig();

  const {
    trendLineData,
    isTrendLineShown,
    setIsTrendLineShown,
    forecastTrendLineData,
  } = hooks.useTrendLine({ data, hasTrendLine, hasForecast });

  const labels = hooks.useLineChartLabels({
    trackerIds,
    isTrendLineShown,
    trackersCollectionId,
  });

  const formattedData = useMemo<FormattedChartItem[]>(() => {
    const [actualData, forecastData] = [
      new Set<FormattedChartItem>(),
      new Set<FormattedChartItem>(),
    ];

    for (const dataItem of data) {
      const { time, values, additionalValues, isForecasted } = dataItem;

      const formattedChartItem: FormattedChartItem = { date: time };

      const labelIds = new Set<string>();

      for (const labelId in values) {
        const isLabelIncluded = labels.some(({ value }) => value === labelId);

        if (!isLabelIncluded) continue;

        const value = values[labelId] || 0;

        const dataKey = utils.getFormattedDataKey(
          labelId,
          isForecasted ? "forecast" : "actual",
        );

        formattedChartItem[dataKey] = Number(value.toFixed(2));

        labelIds.add(labelId);
      }

      if (additionalValues) {
        formattedChartItem.additionalValues = utils.mapAdditionalValues(
          trackersEntities,
          additionalValues,
        );
      }

      if (isTrendLineShown && !isForecasted) {
        formattedChartItem[constants.CATEGORY_TREND_LINE.label] =
          trendLineData[time] || 0;
      }

      if (isTrendLineShown && hasForecast) {
        const dataKey = utils.getFormattedDataKey(
          constants.CATEGORY_TREND_LINE.label,
          "forecast",
        );

        formattedChartItem[dataKey] = forecastTrendLineData[time] || 0;
      }

      switch (true) {
        case isForecasted: {
          forecastData.add(formattedChartItem);

          break;
        }
        default: {
          actualData.add(formattedChartItem);

          break;
        }
      }
    }

    return [...actualData, ...forecastData];
  }, [
    data,
    labels,
    hasForecast,
    trendLineData,
    trackersEntities,
    isTrendLineShown,
    forecastTrendLineData,
  ]);

  const { xDomain, yDomain, yAxisScale, setYAxisScale, yAxisScaleFunction } =
    hooks.useLineChartAxis({
      data,
      labels,
      formattedData,
      trendLineData,
      selectedEvent,
      isTrendLineShown,
      increaseYAxisWidthInPercentage,
    });

  const openCreateDashboardDateRangeModal = useCallback(
    ({ start, end }: LineChartSelection): void => {
      if (!start || !end) return;

      const [formattedStartDate, formattedEndDate] = [
        new Date(Math.min(start, end)).toISOString(),
        new Date(Math.max(start, end)).toISOString(),
      ];

      setModal(
        <CreateDashboardDateRangeModal
          endDate={formattedEndDate}
          startDate={formattedStartDate}
          trackersCollectionId={trackersCollectionId}
          callback={(dashboardDateRangeId) =>
            updateQueryParams({ dateRangeId: dashboardDateRangeId }, history)
          }
        />,
      );
    },
    [history, setModal, trackersCollectionId],
  );

  const {
    isHovered,
    onMouseUp,
    onMouseDown,
    onMouseMove,
    SelectionElement,
    isSelectionActive,
  } = hooks.useLineChartSelection({
    maxValue: forecastStartDate,
    callback: openCreateDashboardDateRangeModal,
  });

  const yAxisPadding = useMemo<Axis["yAxisVerticalPadding"]>(() => {
    const padding = { top: 0, bottom: 0 };

    if (!renderedEvents.length && !forecastStartDate)
      return yAxisVerticalPadding;

    if (renderedEvents.length)
      padding.top += constants.LINE_CHART_EVENT_PADDING_TOP;

    if (forecastStartDate)
      padding.top += constants.LINE_CHART_FORECAST_PADDING_TOP;

    return padding;
  }, [forecastStartDate, renderedEvents.length, yAxisVerticalPadding]);

  const refCallback = useCallback(
    (element: unknown): void => {
      if (!yDomain.length) return;

      if (
        element instanceof Component &&
        "container" in element &&
        element.container instanceof Element
      ) {
        const yAxis = element.container.querySelector(".recharts-yAxis");

        if (!yAxis) return;

        const currentYAxisWidth = yAxis.getBoundingClientRect().width;

        if (currentYAxisWidth) setYAxisWidth(currentYAxisWidth);
      }
    },
    [yDomain],
  );

  const xAxisFormatter = (value: number): string => formatDateHandler(value);

  const yAxisFormatter = (value: number): string =>
    yAxisValueFormatter?.(value) || String(value);

  const eventClickHandler = (value: Event.Data["id"]): void => {
    if (isSelectionActive) return;

    onEventClick(value);
  };

  if (!formattedData.length) return null;

  return (
    <div className={cx(styles.lineChart, className)} style={style}>
      <div className={styles.head}>
        <Labels labels={labels} />
        <LineChartSettings
          scale={yAxisScale}
          hasTrendLine={hasTrendLine}
          handleScaleChange={setYAxisScale}
          isTrendLineShown={isTrendLineShown}
          handleIsTrendLineShownChange={setIsTrendLineShown}
        />
      </div>
      <div className={styles.chartWrapperOuter} style={{ height: graphHeight }}>
        <div className={styles.chartWrapper}>
          <div className={styles.chartWrapperInner} ref={chartWrapperRef}>
            <div className={styles.chart}>
              <ResponsiveContainer>
                <RechartsLineChart
                  ref={refCallback}
                  data={formattedData}
                  onMouseUp={onMouseUp}
                  onMouseMove={onMouseMove}
                  onMouseDown={onMouseDown}
                  style={{
                    userSelect: "none",
                    cursor: isHovered ? "pointer" : "inherit",
                  }}
                >
                  <CartesianGrid opacity={0.25} vertical={false} />
                  <XAxis
                    scale="time"
                    type="number"
                    dataKey="date"
                    stroke={GRAY}
                    domain={xDomain}
                    interval="preserveStartEnd"
                    tickFormatter={xAxisFormatter}
                    tick={constants.LINE_CHART_TICK_STYLES}
                  />
                  <YAxis
                    type="number"
                    domain={yDomain}
                    width={yAxisWidth}
                    padding={yAxisPadding}
                    scale={yAxisScaleFunction()}
                    tickFormatter={yAxisFormatter}
                    tick={constants.LINE_CHART_TICK_STYLES}
                  />
                  <Tooltip
                    allowEscapeViewBox={{ y: isTooltipOverflowAvailable }}
                    content={
                      <CustomTooltip
                        valueFormatter={yAxisValueFormatter}
                        formatDateHandler={formatDateHandler}
                        additionalValueFormatter={yAxisAdditionalValueFormatter}
                      />
                    }
                  />
                  {yAxisWidth
                    ? labels.map(({ value, color }) => (
                        <Line
                          {...constants.LINE_CHART_LINE_PROPS}
                          key={value}
                          stroke={color}
                          dataKey={value}
                          strokeWidth={lineStrokeWidth}
                          isAnimationActive={!isInitialAnimationFinished}
                          onAnimationEnd={() =>
                            setIsActualDataLineAnimationFinished(true)
                          }
                        />
                      ))
                    : null}
                  {yAxisWidth
                    ? labels.map(({ value, color }) => (
                        <Line
                          {...constants.LINE_CHART_LINE_PROPS}
                          key={utils.getFormattedDataKey(value, "forecast")}
                          dataKey={utils.getFormattedDataKey(value, "forecast")}
                          stroke={color}
                          strokeWidth={lineStrokeWidth}
                          onAnimationEnd={() =>
                            setIsForecastDataLineAnimationFinished(true)
                          }
                          animationBegin={forecastLineAnimationDelay}
                          isAnimationActive={!isInitialAnimationFinished}
                          strokeDasharray="5 5"
                        />
                      ))
                    : null}
                  {renderedEvents.map((event) =>
                    LineChartEvent({
                      t,
                      event,
                      yDomain,
                      chartWidth,
                      yAxisPadding,
                      selectedEventId,
                      formatDateHandler,
                      onEventClick: eventClickHandler,
                    }),
                  )}
                  {Boolean(forecastStartDate) &&
                    ForecastDivider({ x: forecastStartDate, t })}
                  {SelectionElement}
                </RechartsLineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
