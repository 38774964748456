import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useAbortController } from "src/hooks";
import { getSuggestedPerspectives } from "src/store/trackers/trackersApi";
import {
  selectLanguagesEntities,
  selectLocationsEntities,
} from "src/store/selectors";
import {
  showToastNotification,
  showDevelopmentError,
  isAbortedRequestErrorTypeGuard,
} from "src/utils";

const SUGGESTED_PERSPECTIVES_LIMIT_PER_REQUEST: number = 4;

type Props = {
  status: LoadingStatus;
  updateStatusHandler: (status: LoadingStatus) => void;
  updateExcludedPerspectivesHandler: (trackers: Tracker.Perspective[]) => void;
};

export const useGetSuggestedPerspectives = ({
  status,
  updateStatusHandler,
  updateExcludedPerspectivesHandler,
}: Props) => {
  const { t } = useTranslation();

  const abortController = useAbortController();

  const locations = useSelector(selectLocationsEntities);

  const languages = useSelector(selectLanguagesEntities);

  const isLoading = useMemo<boolean>(() => status === "loading", [status]);

  const getExcludedPerspectives = useCallback(
    (perspectives: Tracker.Perspective[]): string[] =>
      Array.from(new Set(perspectives)),
    [],
  );

  const getLocation = useCallback(
    (locationId: Location.Data["id"]): Location.Data | undefined =>
      locations[locationId],
    [locations],
  );

  const getLanguage = useCallback(
    (languageId: Language.Data["id"]): Language.Data | undefined =>
      languages[languageId],
    [languages],
  );

  const _getSuggestedPerspectives = async ({
    category,
    callback,
    locationId,
    languageId,
    excludedPerspectives = [],
    limit = SUGGESTED_PERSPECTIVES_LIMIT_PER_REQUEST,
  }: {
    limit?: number;
    locationId: Location.Data["id"];
    languageId: Language.Data["id"];
    category: Tracker.CategoryEntity;
    excludedPerspectives?: Tracker.Perspective[];
    callback?: (trackers: Tracker.Perspective[]) => void;
  }): Promise<Tracker.Perspective[]> => {
    try {
      updateStatusHandler("loading");

      const [location, language] = [
        getLocation(locationId),
        getLanguage(languageId),
      ];

      if (!location || !language) return [];

      const result = await getSuggestedPerspectives(
        {
          limit,
          location,
          language,
          dashboardName: category.dashboardName,
          dashboardDescription: category.dashboardDescription,
          excludedPerspectives: getExcludedPerspectives(excludedPerspectives),
        },
        { signal: abortController?.signal },
      );

      callback?.(result);

      updateStatusHandler("succeeded");
      updateExcludedPerspectivesHandler([...excludedPerspectives, ...result]);

      return result;
    } catch (error) {
      if (isAbortedRequestErrorTypeGuard(error)) return [];

      showDevelopmentError({ error });

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });

      updateStatusHandler("failed");

      callback?.([]);

      return [];
    }
  };

  const cancelGetSuggestedPerspectives = (): void => {
    if (abortController.signal.aborted) return;

    return abortController.abort();
  };

  return {
    isLoading,
    cancelGetSuggestedPerspectives,
    getSuggestedPerspectives: _getSuggestedPerspectives,
  };
};
