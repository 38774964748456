import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./AddCustomSearchModal.module.scss";
import { withError } from "src/hocs";
import { ConfirmModal } from "src/features";
import { Form, Input } from "src/components";
import { removeExtraSpaces } from "src/utils";
import { generateDocId } from "src/store/utils";
import { useElementFocus, useModal, useTemporaryErrors } from "src/hooks";
import {
  SEARCH_DEFAULT_STATUS,
  SEARCH_DEFAULT_DESCRIPTION,
} from "src/constants";

const InputWithError = withError(Input);

type Props = {
  submitHandler: (
    value: Optional<
      Search.CreationData,
      "locationId" | "languageId" | "keywordsDataSource"
    >,
  ) => void;
};

export const AddCustomSearchModal: FC<Props> = ({ submitHandler }) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const [ref, setFocus] = useElementFocus();

  const { errors, setErrors } = useTemporaryErrors(3000);

  const [name, setName] = useState<string>("");

  const isSubmitDisabled = useMemo<boolean>(() => !name, [name]);

  useEffect(() => setFocus(), [setFocus]);

  const onChange = (value: string): void => setName(value);

  const onSubmit = (): void => {
    const errors = validate();

    if (Object.keys(errors).length) return setErrors(errors);

    submitHandler({
      id: generateDocId(),
      status: SEARCH_DEFAULT_STATUS,
      subject: removeExtraSpaces(name),
      description: SEARCH_DEFAULT_DESCRIPTION,
    });

    closeModal();
  };

  function validate() {
    const validationErrors: typeof errors = {};

    if (!name.trim().length)
      validationErrors.name = t(
        "component.modal.add_custom_search.form.validation.search_name_required",
      );

    return validationErrors;
  }

  return (
    <ConfirmModal
      type="success"
      title={t("component.modal.add_custom_search.title")}
      acceptButton={{
        onClick: onSubmit,
        text: t("component.modal.add_custom_search.button.submit"),
      }}
      cancelButton={{
        onClick: closeModal,
        text: t("component.modal.add_custom_search.button.cancel"),
      }}
    >
      <Form
        onSubmit={onSubmit}
        disabled={isSubmitDisabled}
        className={styles.formWrapper}
      >
        <div>
          <InputWithError
            ref={ref}
            value={name}
            error={errors.name}
            changeHandler={onChange}
            placeholder={t(
              "component.modal.add_custom_search.form.placeholder.search_name",
            )}
          />
        </div>
      </Form>
    </ConfirmModal>
  );
};
