import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "../VolumeSharePreview.module.scss";
import { RawChartItem } from "src/components/charts/LineChart/types";
import { TableChartColumn } from "src/components/charts/TableChart/types";
import { LineChart, PieChartWithLabels, TableChart } from "src/components";
import {
  selectWidgetData,
  selectTrackersById,
  selectTrackersCollectionById,
  selectDashboardDateRangeById,
  selectDefaultDashboardDateRangeIdByTrackersCollectionId,
} from "src/store/selectors";
import {
  formatToLocaleNumber,
  formatToMonthFullYearDate,
  calculateValueChangeStyle,
} from "src/utils";

// Inner imports
import { WIDGET_ID } from "../constants";
import { VolumeShareTableItem } from "../types";
import { VolumeShareTableChartTotalLabel } from "../components";
import {
  renderNameColumn,
  renderPieChartValue,
  renderPieChartLabel,
  renderTrendChangeColumn,
  formatVolumeSharePieChart,
  renderPieChartTooltipLabel,
  formatVolumeShareLineChart,
  formatVolumeShareTableChart,
} from "../utils";

export const useVolumeShareCharts = ({
  trackerIds,
  isExpanded,
  dashboardDateRangeId,
  trackersCollectionId,
}: Widget.ChartProps): Widget.Chart[] => {
  const { t } = useTranslation();

  const defaultDashboardDateRangeId = useSelector((state: Store.RootState) =>
    selectDefaultDashboardDateRangeIdByTrackersCollectionId(
      state,
      trackersCollectionId,
    ),
  );

  const defaultWidgetData = useSelector((state: Store.RootState) =>
    selectWidgetData(state, defaultDashboardDateRangeId, WIDGET_ID),
  );

  const dashboardDateRange = useSelector((state: Store.RootState) =>
    selectDashboardDateRangeById(state, dashboardDateRangeId),
  );

  const widgetData = useSelector((state: Store.RootState) =>
    selectWidgetData(state, dashboardDateRangeId, WIDGET_ID),
  );

  const trackersCollection = useSelector((state: Store.RootState) =>
    selectTrackersCollectionById(state, trackersCollectionId),
  );

  const trackers = useSelector((state: Store.RootState) =>
    selectTrackersById(state, trackerIds),
  );

  const trackersCollectionTrackerIds = useMemo<Tracker.Data["id"][]>(
    () => trackersCollection?.trackerIds || [],
    [trackersCollection?.trackerIds],
  );

  const { lineChart, pieChart, tableChart, startDate, endDate } =
    useMemo(() => {
      const { data, startDate = "", endDate = "" } = widgetData || {};

      let { lineChart = {} } = data || {};
      const { pieChart = {}, tableChart = {} } = data || {};

      if (!dashboardDateRange?.type)
        lineChart = defaultWidgetData?.data?.lineChart || {};

      return { lineChart, pieChart, tableChart, startDate, endDate };
    }, [
      widgetData,
      dashboardDateRange?.type,
      defaultWidgetData?.data?.lineChart,
    ]);

  const hasTotal = useMemo<boolean>(
    () => trackerIds.length > 1,
    [trackerIds.length],
  );

  const formattedLineChart = useMemo<RawChartItem[]>(() => {
    if (!Object.values(lineChart).length) return [];

    return formatVolumeShareLineChart({ lineChartData: lineChart, trackerIds });
  }, [lineChart, trackerIds]);

  const formattedTableChart = useMemo<
    Array<
      Widgets.VolumeShare.TableChartValue & Pick<Tracker.Data, "id" | "name">
    >
  >(() => {
    if (!Object.values(tableChart).length) return [];

    return formatVolumeShareTableChart({
      tableChartData: tableChart,
      trackers,
    });
  }, [tableChart, trackers]);

  const formattedPieChart = useMemo<Widgets.PieChart.Item>(() => {
    if (!Object.values(pieChart).length)
      return { startDate, endDate, values: [] };

    return formatVolumeSharePieChart({
      endDate,
      startDate,
      trackerIds,
      pieChartData: pieChart,
      tableChartData: tableChart,
    });
  }, [pieChart, startDate, endDate, trackerIds, tableChart]);

  const tableColumns = useMemo<TableChartColumn<VolumeShareTableItem>[]>(
    () => [
      {
        key: "name",
        width: "50%",
        style: { fontWeight: 500, padding: "15px 10px" },
        label: t("widget.volume_share.table.label.name"),
        valueFormatter: ({ id, name }) =>
          renderNameColumn({
            trackerId: id,
            trackerName: name,
            trackersCollectionTrackerIds,
          }),
      },
      {
        key: "volume",
        label: t("widget.volume_share.table.label.volume"),
        valueFormatter: ({ volume }) => formatToLocaleNumber(volume),
      },
      {
        key: "trendChange",
        label: t("widget.volume_share.table.label.trend_change"),
        style: (value) => calculateValueChangeStyle(value?.trendChange),
        valueFormatter: ({ trendChange }) =>
          renderTrendChangeColumn(trendChange),
      },
    ],
    [t, trackersCollectionTrackerIds],
  );

  return useMemo<Widget.Chart[]>(() => {
    const charts: Widget.Chart[] = [];

    const [hasLineChart, hasPieChart, hasTableChart] = [
      Boolean(formattedLineChart.length),
      Boolean(formattedPieChart.values.length),
      Boolean(formattedTableChart.length),
    ];

    if (hasLineChart)
      charts.push({
        type: "lineChart",
        chart: (
          <LineChart
            trackerIds={trackerIds}
            data={formattedLineChart}
            className={styles.lineChart}
            chartStyles={{ graphHeight: "100%" }}
            trackersCollectionId={trackersCollectionId}
            chartSettings={{
              hasTrendLine: true,
              isTooltipOverflowAvailable: !isExpanded,
            }}
            axis={{
              increaseYAxisWidthInPercentage: 10,
              yAxisValueFormatter: formatToLocaleNumber,
            }}
          />
        ),
      });

    if (hasPieChart)
      charts.push({
        type: "pieChart",
        chart: (
          <>
            <PieChartWithLabels
              hasTotal={hasTotal}
              hasLabels={!isExpanded}
              data={formattedPieChart}
              renderValue={renderPieChartValue}
              renderLabel={renderPieChartLabel}
              className={styles.pieChartWrapper}
              valueFormatter={formatToLocaleNumber}
              dateFormatter={formatToMonthFullYearDate}
              trackersCollectionId={trackersCollectionId}
              renderTooltipLabel={renderPieChartTooltipLabel}
            />
            {isExpanded && hasTableChart && (
              <TableChart
                hasNumeration={false}
                columns={tableColumns}
                data={formattedTableChart}
                className={styles.tableChart}
                hasResponsivePagination={false}
                defaultSort={{ volume: "DESC" }}
                totalLabel={
                  <VolumeShareTableChartTotalLabel
                    data={formattedTableChart}
                    valueFormatter={formatToLocaleNumber}
                  />
                }
              />
            )}
          </>
        ),
      });

    return charts;
  }, [
    hasTotal,
    trackerIds,
    isExpanded,
    tableColumns,
    formattedPieChart,
    formattedLineChart,
    formattedTableChart,
    trackersCollectionId,
  ]);
};
