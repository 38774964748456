import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "src/store";
import { DASHBOARD_DATE_RANGE_TYPES } from "src/constants";
import { selectDashboardDateRangesByTrackersCollectionId } from "src/store/selectors";
import {
  createDashboardDefaultDateRanges,
  fetchDashboardDateRangesByTrackersCollectionId,
} from "src/store/actions";

type Props = {
  trackersCollectionId: TrackersCollection.Data["id"];
};

export const useDashboardDateRangesLoader = ({
  trackersCollectionId,
}: Props) => {
  const dispatch = useAppDispatch();

  const dashboardDateRanges = useSelector((state: Store.RootState) =>
    selectDashboardDateRangesByTrackersCollectionId(
      state,
      trackersCollectionId,
    ),
  );

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const isLoaded = useMemo<boolean>(
    () => Boolean(dashboardDateRanges.length),
    [dashboardDateRanges.length],
  );

  useEffect(() => {
    if (isLoaded) setLoadingStatus("succeeded");
    else setLoadingStatus("idle");
  }, [isLoaded]);

  useEffect(() => {
    if (loadingStatus !== "idle" || dashboardDateRanges.length) return;

    setLoadingStatus("loading");

    dispatch(
      fetchDashboardDateRangesByTrackersCollectionId(trackersCollectionId),
    )
      .unwrap()
      .then((dashboardDateRanges) => {
        const dashboardDateRangeTypesToCreate =
          getMissingDefaultDashboardDateRangeTypes(dashboardDateRanges);

        if (!dashboardDateRangeTypesToCreate.length)
          return setLoadingStatus("succeeded");

        dispatch(
          createDashboardDefaultDateRanges({
            trackersCollectionId,
            types: dashboardDateRangeTypesToCreate,
          }),
        )
          .unwrap()
          .then(() => setLoadingStatus("succeeded"))
          .catch((error) => {
            console.error(error);

            setLoadingStatus("failed");
          });
      })
      .catch((error) => {
        console.error(error);

        setLoadingStatus("failed");
      });
  }, [
    dispatch,
    loadingStatus,
    trackersCollectionId,
    dashboardDateRanges.length,
  ]);

  return { loadingStatus };
};

function getMissingDefaultDashboardDateRangeTypes(
  dashboardDateRanges: DashboardDateRange.Data[],
): DashboardDateRange.Type[] {
  const dashboardDateRangeTypes = new Set<DashboardDateRange.Type>();

  for (const { type } of dashboardDateRanges)
    if (type) dashboardDateRangeTypes.add(type);

  return Array.from(DASHBOARD_DATE_RANGE_TYPES).filter(
    (type) => !dashboardDateRangeTypes.has(type),
  );
}
