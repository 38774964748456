import { Label, ReferenceArea, ReferenceLine } from "recharts";
import cx from "classnames";
import { TFunction } from "i18next";
import isSameDay from "date-fns/isSameDay";

import styles from "./LineChartEvent.module.scss";
import { EventLabel } from "../EventLabel/EventLabel";
import { formatToMonthDayFullYearDate } from "src/utils";
import type { Axis, LineChartEvent as LineChartEventType } from "../../types";

type Props = {
  t: TFunction;
  chartWidth: number;
  yDomain: [number, number];
  event: LineChartEventType;
  selectedEventId: Event.Data["id"];
  yAxisPadding: Axis["yAxisVerticalPadding"];
  formatDateHandler: (time: number) => string;
  onEventClick: (value: Event.Data["id"]) => void;
};

export const LineChartEvent = ({
  t,
  event,
  yDomain,
  chartWidth,
  onEventClick,
  yAxisPadding,
  selectedEventId,
  formatDateHandler,
}: Props): JSX.Element | null => {
  const { id, name, startDate, endDate, color } = event;

  const isOneDayRange = isSameDay(startDate, endDate);

  const isEventSelected = id === selectedEventId;

  const [formattedStartDate, formattedEndDate] = [
    formatToMonthDayFullYearDate(startDate),
    formatToMonthDayFullYearDate(endDate),
  ];

  const label = t("chart.line.event.label", {
    name,
    endDate: formattedEndDate,
    startDate: formattedStartDate,
  });

  const LabelContent = (): JSX.Element | null => {
    if (!isEventSelected) return null;

    return (
      <EventLabel
        event={event}
        chartWidth={chartWidth}
        onEventClick={onEventClick}
        yAxisPadding={yAxisPadding}
        formatDateHandler={formatDateHandler}
      />
    );
  };

  const LabelComponent = (
    <Label
      offset={6}
      value={label}
      position="top"
      className={styles.label}
      {...(isEventSelected ? { content: LabelContent } : {})}
    />
  );

  const eventComponentProps = {
    key: id,
    fill: color,
    opacity: 0.5,
    isFront: true,
    stroke: color,
    onClick: () => onEventClick(id),
    className: cx(styles.wrapper, isEventSelected ? styles.wrapperActive : ""),
  };

  if (isOneDayRange)
    return (
      <ReferenceLine
        {...eventComponentProps}
        segment={[
          { x: startDate, y: yDomain[0] },
          { x: startDate, y: yDomain[1] },
        ]}
      >
        {LabelComponent}
      </ReferenceLine>
    );

  return (
    <ReferenceArea
      {...eventComponentProps}
      x1={startDate}
      x2={endDate}
      y1={yDomain[0]}
      y2={yDomain[1]}
    >
      {LabelComponent}
    </ReferenceArea>
  );
};
