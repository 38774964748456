import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import styles from "./SelectTrackerPerspective.module.scss";
import { useModal } from "src/hooks";
import { Button } from "src/components";
import { Cross, Edit, Plus } from "src/assets/icons";
import { AddTrackerPerspectiveModal } from "src/features";

type Props = {
  locationId: Location.Data["id"];
  languageId: Language.Data["id"];
  category: Tracker.CategoryEntity;
  perspective: Tracker.Perspective;
  suggestedTrackersStatus: LoadingStatus;
  suggestedPerspectivesStatus: LoadingStatus;
  suggestedPerspectives: Tracker.Perspective[];
  selectPerspectiveHandler: (value: Tracker.Perspective) => void;
  setContextSuggestedPerspectivesStatus: (status: LoadingStatus) => void;
  updateSuggestedPerspectivesHandler: (value: Tracker.Perspective[]) => void;
  setContextExcludedPerspectives: (perspectives: Tracker.Perspective[]) => void;
};

export const SelectTrackerPerspective: FC<Props> = ({
  category,
  languageId,
  locationId,
  perspective,
  suggestedPerspectives,
  selectPerspectiveHandler,
  suggestedPerspectivesStatus,
  setContextExcludedPerspectives,
  updateSuggestedPerspectivesHandler,
  setContextSuggestedPerspectivesStatus,
}) => {
  const { t } = useTranslation();

  const { setModal } = useModal();

  const hasPerspective = useMemo<boolean>(
    () => Boolean(perspective),
    [perspective],
  );

  const onEditClick = (): void =>
    setModal(
      <AddTrackerPerspectiveModal
        category={category}
        locationId={locationId}
        languageId={languageId}
        perspective={perspective}
        submitHandler={selectPerspectiveHandler}
        suggestedPerspectives={suggestedPerspectives}
        suggestedPerspectivesStatus={suggestedPerspectivesStatus}
        setContextExcludedPerspectives={setContextExcludedPerspectives}
        updateSuggestedPerspectivesHandler={updateSuggestedPerspectivesHandler}
        setContextSuggestedPerspectivesStatus={
          setContextSuggestedPerspectivesStatus
        }
      />,
    );

  const onCancelClick = (): void => selectPerspectiveHandler("");

  const onSelectClick = (): void =>
    setModal(
      <AddTrackerPerspectiveModal
        category={category}
        locationId={locationId}
        languageId={languageId}
        perspective={perspective}
        submitHandler={selectPerspectiveHandler}
        suggestedPerspectives={suggestedPerspectives}
        suggestedPerspectivesStatus={suggestedPerspectivesStatus}
        setContextExcludedPerspectives={setContextExcludedPerspectives}
        updateSuggestedPerspectivesHandler={updateSuggestedPerspectivesHandler}
        setContextSuggestedPerspectivesStatus={
          setContextSuggestedPerspectivesStatus
        }
      />,
    );

  return (
    <div className={styles.wrapper}>
      {hasPerspective ? (
        <div className={styles.perspectiveWrapper}>
          <span>{perspective}</span>
          <div className={styles.actions}>
            <Button
              buttonSize="small"
              onClick={onEditClick}
              buttonStyle="transparent"
              className={styles.actionButton}
            >
              <Edit />
            </Button>
            <Button
              buttonSize="small"
              onClick={onCancelClick}
              buttonStyle="transparent"
              className={styles.actionButton}
            >
              <Cross />
            </Button>
          </div>
        </div>
      ) : (
        <Button
          buttonSize="small"
          buttonStyle="outlined"
          onClick={onSelectClick}
          className={styles.selectButton}
        >
          <span>
            {t("page.create_tracker.selection.button.select_perspective")}
          </span>
          <Plus />
        </Button>
      )}
    </div>
  );
};
