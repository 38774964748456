import omit from "lodash/omit";

import axios from "src/services/axios";
import firestore from "src/services/firestore";
import {
  DEFAULT_LANGUAGE_ID,
  DEFAULT_LOCATION_ID,
  SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE,
} from "src/constants";
import {
  sortValues,
  triggerGtmEvent,
  showDevelopmentError,
  getLatestTrackerConfig,
} from "src/utils";

import { searchSchema } from "../searches/searchesSchema";
import { API_ENDPOINTS, COLLECTION_IDS } from "../constants";
import {
  generateDocId,
  getTimestamps,
  getFirestoreEntitiesById,
} from "../utils";
import { triggerUpsertDashboardVector } from "../dashboards/dashboardsApi";
import trackersCollectionSchema from "../trackersCollections/trackersCollectionsSchema";

// Inner imports
import * as schemas from "./trackersSchema";
import {
  createTrackersWithSearchesSchema,
  CreateTrackersWithSearchesSchemaType,
} from "./trackersSchema";

export const getTrackersByCompanyId = async (
  companyId: Company.Data["id"],
): Promise<Tracker.Data[]> => {
  const collection = await firestore()
    .collection(COLLECTION_IDS.trackers)
    .where("companyId", "==", companyId)
    .get();

  const result: Tracker.Data[] = [];

  for (const doc of collection.docs) {
    try {
      const tracker = schemas.trackerSchema.validateSync(doc.data());

      result.push({
        ...tracker,
        id: doc.id,
      });
    } catch (error) {
      const errorTitle = "TRACKER VALIDATION ERROR";

      showDevelopmentError({
        additionalTexts: [errorTitle],
        error,
      });
    }
  }

  return result;
};

export const getTrackersByTrackersCollectionId = async (
  trackersCollectionId: TrackersCollection.Data["id"],
): Promise<Tracker.Data[]> => {
  const doc = await firestore()
    .collection(COLLECTION_IDS.trackersCollections)
    .doc(trackersCollectionId)
    .get();

  if (!doc.exists) return [];

  const { trackerIds } = trackersCollectionSchema.validateSync(doc.data());

  return getTrackersByIds(trackerIds);
};

export const getTrackersByIds = async (
  trackerIds: Tracker.Data["id"][],
): Promise<Tracker.Data[]> => {
  const promises = new Set<
    Promise<firestore.DocumentSnapshot<firestore.DocumentData>>
  >();

  for (const trackerId of trackerIds) {
    const docRef = firestore()
      .collection(COLLECTION_IDS.trackers)
      .doc(trackerId)
      .get();

    promises.add(docRef);
  }

  const result = await Promise.all(promises);

  const trackers = new Set<Tracker.Data>();

  for (const doc of result) {
    try {
      const tracker = schemas.trackerSchema.validateSync(doc.data());

      trackers.add({ ...tracker, id: doc.id });
    } catch (error) {
      const errorTitle = "TRACKER VALIDATION ERROR";

      showDevelopmentError({ additionalTexts: [errorTitle], error });
    }
  }

  return [...trackers];
};

export const createTracker = async (
  payload: Store.CreateEntity<Omit<Tracker.Data, "createdAt" | "updatedAt">>,
): Promise<Tracker.Data> => {
  const { createdAt, updatedAt } = getTimestamps();

  const _payload = {
    ...payload,
    createdAt,
    updatedAt,
  };

  const doc = await firestore()
    .collection(COLLECTION_IDS.trackers)
    .add(_payload);

  triggerGtmEvent("CreateTrackerSave", { trackerId: doc.id });

  return { ..._payload, id: doc.id };
};

export const createTrackersWithSearches = async ({
  userId,
  trackers,
  companyId,
  dashboardName,
  dashboardDescription,
}: {
  userId: User.Data["id"];
  companyId: Company.Data["id"];
  dashboardName: Dashboard.Data["id"];
  dashboardDescription: Dashboard.Data["description"];
  trackers: Array<Tracker.CreationData & Pick<Tracker.Data, "searchIds">>;
}): Promise<CreateTrackersWithSearchesSchemaType> => {
  const formattedTrackers = trackers.map((tracker) =>
    omit(tracker, "locationId", "languageId", "id"),
  );

  const response = await axios.post(API_ENDPOINTS.createTrackersWithSearches, {
    companyId,
    dashboardName,
    authorId: userId,
    dashboardDescription,
    createDashboard: false,
    trackers: formattedTrackers,
  });

  return createTrackersWithSearchesSchema.validateSync(response.data);
};

export const updateTracker = async (
  { id, changes }: Store.UpdateEntity<Tracker.Data>,
  companyId: Company.Data["id"],
): Promise<Store.UpdateEntity<Tracker.Data>> => {
  const { updatedAt } = getTimestamps();

  const _changes = { ...changes, updatedAt };

  await firestore()
    .collection(COLLECTION_IDS.trackers)
    .doc(id)
    .set(_changes, { merge: true });

  if (getShouldUpsertDashboardVectorTrigger(_changes)) {
    const triggerUpsertDashboardVectorPromises: Promise<void>[] = [];

    const collection = await firestore()
      .collection(COLLECTION_IDS.trackersCollections)
      .where("companyId", "==", companyId)
      .where("trackerIds", "array-contains", id)
      .get();

    for (const { id: trackersCollectionId } of collection.docs)
      triggerUpsertDashboardVectorPromises.push(
        triggerUpsertDashboardVector(trackersCollectionId),
      );

    Promise.all(triggerUpsertDashboardVectorPromises).catch(console.error);
  }

  return { id, changes: _changes };
};

export const updateTrackers = async (
  payload: Store.UpdateEntity<Tracker.Data>[],
): Promise<Store.UpdateEntity<Tracker.Data>[]> => {
  const { updatedAt } = getTimestamps();

  const updatedPayload: Store.UpdateEntity<Tracker.Data>[] = [];

  const triggerUpsertDashboardVectorPromises: Promise<void>[] = [];

  const batch = firestore().batch();

  for (const { id, changes } of payload) {
    const _changes = { ...changes, updatedAt };

    const docRef = firestore().collection(COLLECTION_IDS.trackers).doc(id);

    batch.set(docRef, _changes, { merge: true });

    updatedPayload.push({ id, changes: _changes });

    triggerUpsertDashboardVectorPromises.push(triggerUpsertDashboardVector(id));
  }

  await batch.commit();

  Promise.all(triggerUpsertDashboardVectorPromises).catch(console.error);

  return updatedPayload;
};

export const updateTrackersByAuthorId = async (
  {
    changes,
    authorId,
  }: {
    changes: Store.UpdateEntity<Tracker.Data>["changes"];
    authorId: Tracker.Data["authorId"];
  },
  companyId: Company.Data["id"],
): Promise<Store.UpdateEntity<Tracker.Data>[]> => {
  const { updatedAt } = getTimestamps();

  const updatedPayload: Store.UpdateEntity<Tracker.Data>[] = [];

  const triggerUpsertDashboardVectorPromises: Promise<void>[] = [];

  const batch = firestore().batch();

  const trackersByAuthorId = await firestore()
    .collection(COLLECTION_IDS.trackers)
    .where("companyId", "==", companyId)
    .where("authorId", "==", authorId)
    .get();

  for (const { id, ref } of trackersByAuthorId.docs) {
    const _changes = { ...changes, updatedAt };

    batch.set(ref, _changes, { merge: true });

    updatedPayload.push({ id, changes: _changes });

    if (getShouldUpsertDashboardVectorTrigger(changes)) {
      const collection = await firestore()
        .collection(COLLECTION_IDS.trackersCollections)
        .where("companyId", "==", companyId)
        .where("trackerIds", "array-contains", id)
        .get();

      for (const { id: trackersCollectionId } of collection.docs)
        triggerUpsertDashboardVectorPromises.push(
          triggerUpsertDashboardVector(trackersCollectionId),
        );
    }
  }

  await batch.commit();

  Promise.all(triggerUpsertDashboardVectorPromises).catch(console.error);

  return updatedPayload;
};

export const deleteTracker = (id: Tracker.Data["id"]): Promise<void> =>
  firestore().collection(COLLECTION_IDS.trackers).doc(id).delete();

export const duplicateDashboardTrackers = async ({
  companyId,
  userId,
  dashboardId,
}: {
  companyId: Company.Data["id"];
  userId: User.Data["id"];
  dashboardId: Dashboard.Data["id"];
}): Promise<Tracker.Data[]> => {
  const response = await axios.post(API_ENDPOINTS.duplicateDashboardTrackers, {
    companyId,
    authorId: userId,
    dashboardId,
  });

  const { trackerIds } = schemas.duplicatedDashboardTrackersSchema.validateSync(
    response.data,
  );

  const collection = await getFirestoreEntitiesById(
    trackerIds,
    COLLECTION_IDS.trackers,
  );

  const result: Tracker.Data[] = [];

  for (const doc of collection) {
    try {
      const tracker = schemas.trackerSchema.validateSync(doc.data());

      triggerGtmEvent("CreateTrackerSave", { trackerId: doc.id });

      result.push({ ...tracker, id: doc.id });
    } catch (error) {
      const errorTitle = "TRACKER VALIDATION ERROR";

      showDevelopmentError({
        additionalTexts: [errorTitle],
        error,
      });
    }
  }

  return result;
};

export const getSuggestedTrackers: ServerRequest<
  {
    limit: number;
    perspective?: string;
    dashboardName: string;
    location: Location.Data;
    language: Language.Data;
    excludedSubjects?: string[];
    dashboardDescription: string;
    keywordsDataSource: Search.KeywordsDataSource;
  },
  Promise<Tracker.CreationData[]>
> = async (data, configuration) => {
  const { location, language, keywordsDataSource, ...rest } = data;

  const { id: locationId, country } = location;

  const { id: languageId, name: languageName } = language;

  const response = await axios.post(
    API_ENDPOINTS.getSuggestedTrackers,
    { country, language: languageName, ...rest },
    configuration,
  );

  const trackers = schemas.suggestedTrackersSchema.validateSync(response.data);

  return formatSuggestedTrackers({
    trackers,
    locationId,
    languageId,
    keywordsDataSource,
  });
};

export const getSuggestedCategories: ServerRequest<
  { query: string },
  Promise<Tracker.CategoryEntity[]>
> = async ({ query }, configuration) => {
  const response = await axios.post(
    API_ENDPOINTS.getSuggestedCategories,
    { input: query },
    configuration,
  );

  return schemas.trackerSuggestedCategoriesSchema.validateSync(response.data);
};

export const getSuggestedPerspectives: ServerRequest<
  {
    limit: number;
    dashboardName: string;
    location: Location.Data;
    language: Language.Data;
    dashboardDescription: string;
    excludedPerspectives?: Tracker.Perspective[];
  },
  Promise<Tracker.Perspective[]>
> = async (data, configuration) => {
  const { location, language, excludedPerspectives, ...rest } = data;

  const { country } = location;

  const { name: languageName } = language;

  const response = await axios.post(
    API_ENDPOINTS.getSuggestedPerspectives,
    {
      country,
      language: languageName,
      excluded: excludedPerspectives,
      ...rest,
    },
    configuration,
  );

  return schemas.suggestedPerspectivesSchema.validateSync(response.data);
};

export const getUserLatestTrackerConfig = async (
  trackers: Tracker.Data[],
): Promise<
  Pick<Search.Data, "locationId" | "languageId" | "keywordsDataSource">
> => {
  const sortedTrackers = [...trackers].sort((a, b) =>
    sortValues(a.createdAt, b.createdAt, "DESC"),
  );

  const latestTracker = [...sortedTrackers][0];

  if (!latestTracker)
    return {
      locationId: DEFAULT_LOCATION_ID,
      languageId: DEFAULT_LANGUAGE_ID,
      keywordsDataSource: SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE,
    };

  const { searchIds } = schemas.trackerSchema.validateSync(latestTracker);

  const searches = await getFirestoreEntitiesById(
    searchIds,
    COLLECTION_IDS.searches,
  );

  const latestTrackerKeywordsDataSource =
    latestTracker.keywordsDataSources[0] || SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE;

  if (!searches.length)
    return {
      locationId: DEFAULT_LOCATION_ID,
      languageId: DEFAULT_LANGUAGE_ID,
      keywordsDataSource: latestTrackerKeywordsDataSource,
    };

  const validatedSearches = new Set<Search.Data>();

  for (const doc of searches) {
    try {
      const search = searchSchema.validateSync(doc.data());

      validatedSearches.add({ ...search, id: doc.id });
    } catch (error) {
      const errorTitle = "SEARCH VALIDATION ERROR";

      showDevelopmentError({
        additionalTexts: [errorTitle],
        error,
      });
    }
  }

  return getLatestTrackerConfig(latestTracker, [...validatedSearches]);
};

export const getTrackerDescription = async (
  payload: Pick<Tracker.CreationData, "name">,
  location: Location.Data,
  excludedDescriptions: Tracker.CreationData["description"][] = [],
): Promise<Tracker.CreationData["description"]> => {
  const response = await axios.post(API_ENDPOINTS.getTrackerDescription, {
    excludedDescriptions,
    subject: payload.name,
    country: location.name,
  });

  return schemas.trackerDescriptionSchema.validateSync(response.data);
};

export const subscribeOnTrackersWithPartialData = (
  callback: (trackerIds: Tracker.Data["id"][]) => void,
): (() => void) => {
  const collectionRef = firestore().collection(
    COLLECTION_IDS.trackersWithPartialData,
  );

  return collectionRef.onSnapshot((ref) => {
    const trackerIds = new Set<Tracker.Data["id"]>();

    for (const doc of ref.docs) trackerIds.add(doc.id);

    callback([...trackerIds]);
  });
};

function formatSuggestedTrackers({
  trackers,
  languageId,
  locationId,
  keywordsDataSource,
}: {
  trackers: schemas.SuggestedTrackersSchemaType;
  locationId: Location.Data["id"];
  languageId: Language.Data["id"];
  keywordsDataSource: Search.KeywordsDataSource;
}): Tracker.CreationData[] {
  return trackers.map(({ subject, category, description }) => ({
    category,
    locationId,
    languageId,
    description,
    name: subject,
    id: generateDocId(),
    keywordsDataSources: [keywordsDataSource],
  }));
}

function getShouldUpsertDashboardVectorTrigger(
  trackerChanges: Store.UpdateEntity<Tracker.Data>["changes"],
): boolean {
  return "name" in trackerChanges;
}
