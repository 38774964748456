import subMonths from "date-fns/subMonths";
import addMonths from "date-fns/addMonths";
import { checkIsSameMonth, formatToYearMonth } from "src/utils";

export const getExistingDashboardDateRangeByEvent = (
  event: Omit<Event.Data, "id">,
  dashboardDateRanges: DashboardDateRange.Data[],
): DashboardDateRange.Data | null => {
  const [formattedStartDate, formattedEndDate] = [
    formatToYearMonth(event.startDate),
    formatToYearMonth(event.endDate),
  ];

  for (const dashboardDateRange of dashboardDateRanges) {
    const { startDate, endDate } = dashboardDateRange;

    if (!startDate || !endDate) continue;

    if (formattedStartDate === startDate && formattedEndDate === endDate)
      return dashboardDateRange;
  }

  return null;
};

export const generateDashboardDateRangeFromEvent = (
  event: Omit<Event.Data, "id">,
  dataStartDate: ISODateString = "",
): Omit<DashboardDateRange.Data, "id"> => {
  const isSameMonth = checkIsSameMonth(event.startDate, event.endDate);

  let [formattedStartDate, formattedEndDate] = [
    formatToYearMonth(event.startDate),
    formatToYearMonth(event.endDate),
  ];

  if (isSameMonth && !dataStartDate)
    formattedStartDate = formatToYearMonth(
      subMonths(new Date(formattedStartDate), 1),
    );

  if (isSameMonth && dataStartDate) {
    const isSameMonthAsDataStartDate = checkIsSameMonth(
      dataStartDate,
      formattedStartDate,
    );

    if (isSameMonthAsDataStartDate)
      formattedEndDate = formatToYearMonth(
        addMonths(new Date(formattedEndDate), 1),
      );
    else
      formattedStartDate = formatToYearMonth(
        subMonths(new Date(formattedStartDate), 1),
      );
  }

  return {
    type: null,
    category: "custom",
    authorId: event.authorId,
    endDate: formattedEndDate,
    createdAt: event.createdAt,
    updatedAt: event.updatedAt,
    companyId: event.companyId,
    startDate: formattedStartDate,
    trackersCollectionId: event.dashboardId,
  };
};
