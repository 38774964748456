import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useAbortController } from "src/hooks";
import { getSuggestedTrackers } from "src/store/trackers/trackersApi";
import {
  selectLanguagesEntities,
  selectLocationsEntities,
} from "src/store/selectors";
import {
  showToastNotification,
  showDevelopmentError,
  isAbortedRequestErrorTypeGuard,
} from "src/utils";

const SUGGESTED_TRACKERS_LIMIT_PER_REQUEST: number = 5;

type Props = {
  status: LoadingStatus;
  updateStatusHandler: (status: LoadingStatus) => void;
  updateExcludedTrackersHandler: (trackers: Tracker.CreationData[]) => void;
};

export const useGetSuggestedTrackers = ({
  status,
  updateStatusHandler,
  updateExcludedTrackersHandler,
}: Props) => {
  const { t } = useTranslation();

  const abortController = useAbortController();

  const locations = useSelector(selectLocationsEntities);

  const languages = useSelector(selectLanguagesEntities);

  const isLoading = useMemo<boolean>(() => status === "loading", [status]);

  const getExcludedTrackers = useCallback(
    (trackers: Tracker.CreationData[]): string[] => {
      const trackerNames = trackers.map(({ name }) => name);

      return Array.from(new Set(trackerNames));
    },
    [],
  );

  const getLocation = useCallback(
    (locationId: Location.Data["id"]): Location.Data | undefined =>
      locations[locationId],
    [locations],
  );

  const getLanguage = useCallback(
    (languageId: Language.Data["id"]): Language.Data | undefined =>
      languages[languageId],
    [languages],
  );

  const _getSuggestedTrackers = async ({
    category,
    callback,
    locationId,
    languageId,
    perspective,
    keywordsDataSource,
    excludedTrackers = [],
    limit = SUGGESTED_TRACKERS_LIMIT_PER_REQUEST,
  }: {
    limit?: number;
    perspective?: Tracker.Perspective;
    category: Tracker.CategoryEntity;
    callback?: (trackers: Tracker.CreationData[]) => void;
    locationId: Location.Data["id"];
    languageId: Language.Data["id"];
    excludedTrackers?: Tracker.CreationData[];
    keywordsDataSource: Search.KeywordsDataSource;
  }): Promise<Tracker.CreationData[]> => {
    try {
      updateStatusHandler("loading");

      const [location, language] = [
        getLocation(locationId),
        getLanguage(languageId),
      ];

      if (!location || !language) return [];

      const result = await getSuggestedTrackers(
        {
          limit,
          location,
          language,
          perspective,
          keywordsDataSource,
          dashboardName: category.dashboardName,
          dashboardDescription: category.dashboardDescription,
          excludedSubjects: getExcludedTrackers(excludedTrackers),
        },
        { signal: abortController?.signal },
      );

      callback?.(result);

      updateStatusHandler("succeeded");
      updateExcludedTrackersHandler([...excludedTrackers, ...result]);

      return result;
    } catch (error) {
      if (isAbortedRequestErrorTypeGuard(error)) return [];

      showDevelopmentError({
        error,
      });

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });

      updateStatusHandler("failed");

      callback?.([]);

      return [];
    }
  };

  const cancelGetSuggestedTrackers = (): void => {
    if (abortController.signal.aborted) return;

    return abortController.abort();
  };

  return {
    isLoading,
    cancelGetSuggestedTrackers,
    getSuggestedTrackers: _getSuggestedTrackers,
  };
};
