import {
  FC,
  memo,
  useMemo,
  useState,
  useCallback,
  useLayoutEffect,
} from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./SuggestedSearches.module.scss";
import { useUnmountEffect } from "src/hooks";
import { Button, Preloader } from "src/components";
import { ChevronDown, Stars } from "src/assets/icons";
import { getLocationDefaultLanguageId } from "src/utils";
import { CountriesDropdown, LanguagesDropdown } from "src/features";
import { useGetSuggestedSearches } from "src/pages/Trackers/CreateTracker/hooks";
import {
  selectLocationById,
  selectLanguageById,
  selectLanguagesByKeywordsDataSource,
  selectLocationsByKeywordsDataSource,
} from "src/store/selectors";

// Inner imports
import { SuggestedSearch } from "./components";

type Props = {
  subject: string;
  isExpanded?: boolean;
  languageId: Language.Data["id"];
  locationId: Location.Data["id"];
  category: Tracker.Data["category"];
  suggestedSearchesStatus: LoadingStatus;
  excludedSearches: Search.CreationData[];
  selectedSearches: Search.CreationData[];
  description: Tracker.Data["description"];
  suggestedSearches: Search.CreationData[];
  keywordsDataSource: Search.KeywordsDataSource;
  filteredSuggestedSearches: Search.CreationData[];
  selectSearchHandler: (value: Search.CreationData) => void;
  updateLocationIdHandler: (value: Location.Data["id"]) => void;
  updateLanguageIdHandler: (value: Language.Data["id"]) => void;
  updateSuggestedSearchesStatusHandler: (status: LoadingStatus) => void;
  updateExcludedSearchesHandler: (values: Search.CreationData[]) => void;
  updateSuggestedSearchesHandler: (newSearches: Search.CreationData[]) => void;
};

export const SuggestedSearches: FC<Props> = memo(
  ({
    subject,
    category,
    locationId,
    languageId,
    description,
    selectSearchHandler,
    keywordsDataSource,
    isExpanded: defaultIsExpanded = false,
    selectedSearches,
    filteredSuggestedSearches,
    suggestedSearchesStatus,
    updateSuggestedSearchesStatusHandler,
    updateExcludedSearchesHandler,
    updateSuggestedSearchesHandler,
    updateLocationIdHandler,
    updateLanguageIdHandler,
  }) => {
    const { t } = useTranslation();

    const locations = useSelector((state: Store.RootState) =>
      selectLocationsByKeywordsDataSource(state, keywordsDataSource),
    );

    const languages = useSelector((state: Store.RootState) =>
      selectLanguagesByKeywordsDataSource(state, keywordsDataSource),
    );

    const location = useSelector((state: Store.RootState) =>
      selectLocationById(state, locationId),
    );

    const language = useSelector((state: Store.RootState) =>
      selectLanguageById(state, languageId),
    );

    const { isLoading, getSuggestedSearches, cancelGetSuggestedSearches } =
      useGetSuggestedSearches({
        keywordsDataSource,
        suggestedSearchesStatus,
        updateSuggestedSearchesStatusHandler,
        updateExcludedSearchesHandler,
      });

    const [isExpanded, setIsExpanded] = useState<boolean>(defaultIsExpanded);

    const hasSearchesAutoFetch = useMemo<boolean>(() => {
      if (suggestedSearchesStatus === "loading" || !isExpanded) return false;

      if (suggestedSearchesStatus !== "idle") return false;

      return !filteredSuggestedSearches.length;
    }, [isExpanded, suggestedSearchesStatus, filteredSuggestedSearches.length]);

    const locationName = useMemo<string>(
      () => location?.name || "",
      [location?.name],
    );

    const languageName = useMemo<string>(
      () => language?.name || "",
      [language?.name],
    );

    const SuggestedSearches = useMemo<JSX.Element>(() => {
      switch (true) {
        case Boolean(filteredSuggestedSearches.length):
          return (
            <>
              {isLoading && (
                <div className={styles.loaderWrapper}>
                  <Preloader
                    type="bar"
                    text={t(
                      "page.create_tracker.configuration.loader.download_suggested_searches",
                    )}
                  />
                </div>
              )}
              {filteredSuggestedSearches.map((search) => (
                <SuggestedSearch
                  key={search.id}
                  search={search}
                  searchCreateHandler={selectSearchHandler}
                />
              ))}
            </>
          );
        case isLoading:
          return (
            <Preloader
              type="bar"
              text={t(
                "page.create_tracker.configuration.loader.download_suggested_searches",
              )}
              className={styles.preloader}
            />
          );
        default:
          return (
            <div className={styles.placeholder}>
              {t(
                "page.create_tracker.configuration.label.no_suggested_searches",
              )}
            </div>
          );
      }
    }, [selectSearchHandler, filteredSuggestedSearches, isLoading, t]);

    const updateSuggestedSearches = useCallback(
      ({
        locationId: newLocationId = locationId,
        languageId: newLanguageId = languageId,
      }: {
        locationId?: Location.Data["id"];
        languageId?: Language.Data["id"];
      }): Promise<Search.CreationData[]> =>
        getSuggestedSearches({
          category,
          description,
          query: subject,
          locationId: newLocationId,
          languageId: newLanguageId,
          excludedSearches: selectedSearches,
          callback: updateSuggestedSearchesHandler,
        }),
      [
        subject,
        category,
        locationId,
        languageId,
        description,
        selectedSearches,
        getSuggestedSearches,
        updateSuggestedSearchesHandler,
      ],
    );

    useUnmountEffect(cancelGetSuggestedSearches);

    useLayoutEffect(() => {
      if (hasSearchesAutoFetch) updateSuggestedSearches({}).catch();
    }, [hasSearchesAutoFetch, updateSuggestedSearches]);

    const onLocationIdChange = (value: Location.Data["id"]): void => {
      updateLocationIdHandler(value);

      const languageId = getLocationDefaultLanguageId(
        value,
        locations,
        languages,
      );

      updateSuggestedSearches({ locationId: value, languageId }).catch();
    };

    const onLanguageIdChange = (value: Language.Data["id"]): void => {
      updateLanguageIdHandler(value);

      updateSuggestedSearches({ languageId: value }).catch();
    };

    const onExpandClick = (): void => setIsExpanded(true);

    if (!isExpanded)
      return (
        <div className={styles.wrapper}>
          <div className={styles.expandButtonWrapper}>
            <Button
              className={styles.expandButton}
              buttonSize="small"
              buttonStyle="transparent"
              onClick={onExpandClick}
            >
              <span>
                {t("page.create_tracker.configuration.button.add_search")}
              </span>
              <ChevronDown />
            </Button>
          </div>
        </div>
      );

    return (
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <div className={styles.titleLabel}>
            <Stars />
            <span>
              {t("page.create_tracker.configuration.label.suggested_searches")}
            </span>
          </div>
          <div className={styles.settings}>
            <div className={styles.location} title={locationName}>
              <CountriesDropdown
                isDisabled={isLoading}
                locationId={locationId}
                setLocationId={onLocationIdChange}
                keywordsDataSource={keywordsDataSource}
              />
            </div>
            <div className={styles.language} title={languageName}>
              <LanguagesDropdown
                isDisabled={isLoading}
                languageId={languageId}
                setLanguageId={onLanguageIdChange}
                keywordsDataSource={keywordsDataSource}
              />
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.suggestedSearches}>{SuggestedSearches}</div>
        </div>
      </div>
    );
  },
);
