import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./AddTrackerPerspectiveModal.module.scss";
import { withError } from "src/hocs";
import { ConfirmModal } from "src/features";
import { Form, Label, Input, Button, Preloader } from "src/components";
import { formatTrackerPerspectiveKey, removeExtraSpaces } from "src/utils";
import { useGetSuggestedPerspectives } from "src/pages/Trackers/CreateTracker/hooks";
import {
  useModal,
  useElementFocus,
  useUnmountEffect,
  useTemporaryErrors,
} from "src/hooks";

const InputWithError = withError(Input);

type Props = {
  locationId: Location.Data["id"];
  languageId: Language.Data["id"];
  perspective: Tracker.Perspective;
  category: Tracker.CategoryEntity;
  suggestedPerspectivesStatus: LoadingStatus;
  suggestedPerspectives: Tracker.Perspective[];
  submitHandler: (value: Tracker.Perspective) => void;
  setContextSuggestedPerspectivesStatus: (status: LoadingStatus) => void;
  updateSuggestedPerspectivesHandler: (value: Tracker.Perspective[]) => void;
  setContextExcludedPerspectives: (perspectives: Tracker.Perspective[]) => void;
};

export const AddTrackerPerspectiveModal: FC<Props> = ({
  category,
  locationId,
  languageId,
  submitHandler,
  setContextExcludedPerspectives,
  perspective: defaultPerspective,
  updateSuggestedPerspectivesHandler,
  setContextSuggestedPerspectivesStatus,
  suggestedPerspectives: defaultSuggestedPerspectives,
  suggestedPerspectivesStatus: defaultSuggestedPerspectiveStatus,
}) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const { errors, setErrors } = useTemporaryErrors(3000);

  const [ref, setFocus] = useElementFocus();

  const [perspective, setPerspective] = useState<string>(defaultPerspective);

  const [suggestedPerspectives, setSuggestedPerspectives] = useState<
    Tracker.Perspective[]
  >(defaultSuggestedPerspectives);

  const [suggestedPerspectivesStatus, setSuggestedPerspectivesStatus] =
    useState<LoadingStatus>(defaultSuggestedPerspectiveStatus);

  const updateSuggestedPerspectivesStatus = (value: LoadingStatus): void => {
    setSuggestedPerspectivesStatus(value);

    setContextSuggestedPerspectivesStatus(value);
  };

  const { getSuggestedPerspectives, cancelGetSuggestedPerspectives } =
    useGetSuggestedPerspectives({
      status: suggestedPerspectivesStatus,
      updateStatusHandler: updateSuggestedPerspectivesStatus,
      updateExcludedPerspectivesHandler: setContextExcludedPerspectives,
    });

  const isSubmitDisabled = useMemo<boolean>(() => !perspective, [perspective]);

  const hasSuggestedPerspectives = useMemo<boolean>(
    () => suggestedPerspectives.length > 0,
    [suggestedPerspectives],
  );

  const isSuggestedPerspectivesLoading = useMemo<boolean>(
    () => suggestedPerspectivesStatus === "loading",
    [suggestedPerspectivesStatus],
  );

  useEffect(() => setFocus(), [setFocus]);

  useEffect(() => {
    if (suggestedPerspectivesStatus !== "idle" || suggestedPerspectives.length)
      return;

    getSuggestedPerspectives({
      category,
      locationId,
      languageId,
      callback: (value) => {
        setSuggestedPerspectives(value);

        updateSuggestedPerspectivesHandler(value);
      },
      excludedPerspectives: [perspective, ...suggestedPerspectives].filter(
        Boolean,
      ),
    }).catch();
  }, [
    category,
    languageId,
    locationId,
    perspective,
    suggestedPerspectives,
    getSuggestedPerspectives,
    suggestedPerspectivesStatus,
    suggestedPerspectives.length,
    updateSuggestedPerspectivesHandler,
  ]);

  useUnmountEffect(() => {
    cancelGetSuggestedPerspectives();

    if (suggestedPerspectivesStatus === "loading") {
      setSuggestedPerspectivesStatus("idle");

      setContextSuggestedPerspectivesStatus("idle");
    }
  });

  const onChange = (value: string): void => setPerspective(value);

  const onSelect = (value: Tracker.Perspective): void => {
    submitHandler(value);

    closeModal();
  };

  const onSubmit = (): void => {
    const errors = validate();

    if (Object.keys(errors).length) return setErrors(errors);

    submitHandler(removeExtraSpaces(perspective));

    closeModal();
  };

  function validate() {
    const validationErrors: typeof errors = {};

    if (!perspective.trim().length)
      validationErrors.name = t(
        "component.modal.add_tracker_perspective.form.validation.perspective_required",
      );

    return validationErrors;
  }

  return (
    <ConfirmModal
      type="success"
      title={t("component.modal.add_tracker_perspective.title")}
      acceptButton={{
        onClick: onSubmit,
        disabled: isSubmitDisabled,
        text: t("component.modal.add_tracker_perspective.button.submit"),
      }}
      cancelButton={{
        onClick: closeModal,
        text: t("component.modal.add_tracker_perspective.button.cancel"),
      }}
    >
      <Form
        onSubmit={onSubmit}
        disabled={isSubmitDisabled}
        className={styles.formWrapper}
      >
        <div>
          <Label
            leftText={t(
              "component.modal.add_tracker_perspective.form.label.perspective",
            )}
          />
          <InputWithError
            ref={ref}
            value={perspective}
            error={errors.value}
            changeHandler={onChange}
            placeholder={t(
              "component.modal.add_tracker_perspective.form.placeholder.perspective",
            )}
          />
        </div>
        {isSuggestedPerspectivesLoading && (
          <div className={styles.loader}>
            <Preloader
              type="bar"
              text={t(
                "component.modal.add_tracker_perspective.loader.download_suggested_perspectives",
              )}
            />
          </div>
        )}
        {!hasSuggestedPerspectives && !isSuggestedPerspectivesLoading && (
          <div className={styles.placeholder}>
            {t(
              "component.modal.add_tracker_perspective.placeholder.no_suggestions",
            )}
          </div>
        )}
        {hasSuggestedPerspectives && !isSuggestedPerspectivesLoading && (
          <div className={styles.suggestedPerspectives}>
            {suggestedPerspectives.map((perspective, index) => (
              <Button
                type="button"
                buttonSize="small"
                buttonStyle="outlined"
                onClick={() => onSelect(perspective)}
                className={styles.suggestedPerspective}
                key={formatTrackerPerspectiveKey(perspective, index)}
              >
                {perspective}
              </Button>
            ))}
          </div>
        )}
      </Form>
    </ConfirmModal>
  );
};
