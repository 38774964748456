import { useCallback, useEffect, useMemo } from "react";
import isAfter from "date-fns/isAfter";
import { useSelector } from "react-redux";

import { useAppDispatch } from "src/store";
import { WIDGET_IDS_WITH_FORECAST } from "src/constants";
import {
  fetchWidgets,
  createWidgetSyncs,
  changeWidgetStatus,
} from "src/store/actions";
import {
  composeWidgetSyncId,
  showToastNotification,
  showDevelopmentError,
} from "src/utils";
import {
  selectWidgetsInfo,
  selectWidgetStatus,
  selectWidgetSyncById,
  selectApplicationInfo,
  selectWidgetLastUpdatedDate,
  selectAvailableDashboardById,
  selectDashboardDateRangeById,
  selectIsCompanyDataUpdateAllowed,
  selectIsCompanyTrackersCollection,
  selectTrackersCollectionUpdateDate,
  selectAvailableWidgetIdsByCompanyId,
  selectTrackersCollectionKeywordsDataSources,
} from "src/store/selectors";

// Inner imports
import { useIsWidgetForecastCalculated } from "./useIsWidgetForecastCalculated";

type Props = {
  isReadOnly: boolean;
  widgetId: Widget.IdType;
  trackersCollectionId: TrackersCollection.Data["id"];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
};

export const useWidgetCalculation = ({
  widgetId,
  isReadOnly,
  trackersCollectionId,
  dashboardDateRangeId,
}: Props) => {
  const dispatch = useAppDispatch();

  const dashboard = useSelector((state: Store.RootState) =>
    selectAvailableDashboardById(state, trackersCollectionId, isReadOnly),
  );

  const dashboardCompanyId = useMemo<Dashboard.Data["companyId"]>(
    () => dashboard?.companyId || "",
    [dashboard?.companyId],
  );

  const isDataUpdateAllowed = useSelector((state: Store.RootState) =>
    selectIsCompanyDataUpdateAllowed(state, dashboardCompanyId),
  );

  const availableWidgetIds = useSelector((state: Store.RootState) =>
    selectAvailableWidgetIdsByCompanyId(state, dashboardCompanyId),
  );

  const widgetStatus = useSelector((state: Store.RootState) =>
    selectWidgetStatus(state, dashboardDateRangeId, widgetId),
  );

  const widgetUpdatedAt = useSelector((state: Store.RootState) =>
    selectWidgetLastUpdatedDate(state, dashboardDateRangeId, widgetId),
  );

  const trackersCollectionUpdatedAt = useSelector((state: Store.RootState) =>
    selectTrackersCollectionUpdateDate(state, trackersCollectionId),
  );

  const trackersCollectionKeywordsDataSources = useSelector(
    (state: Store.RootState) =>
      selectTrackersCollectionKeywordsDataSources(state, trackersCollectionId),
  );

  const isCompanyTrackersCollection = useSelector((state: Store.RootState) =>
    selectIsCompanyTrackersCollection(state, trackersCollectionId),
  );

  const widgets = useSelector(selectWidgetsInfo);

  const widgetSync = useSelector((state: Store.RootState) =>
    selectWidgetSyncById(
      state,
      composeWidgetSyncId({
        widgetId,
        trackersCollectionId,
        dashboardDateRangeId,
      }),
    ),
  );

  const { keywordsSettings } = useSelector(selectApplicationInfo);

  const dashboardDateRange = useSelector((state: Store.RootState) =>
    selectDashboardDateRangeById(state, dashboardDateRangeId),
  );

  const isForecastCalculated = useIsWidgetForecastCalculated({
    widgetId,
    dashboardDateRangeId,
  });

  const isForecastAvailable = useMemo<boolean>(
    () =>
      dashboardDateRange?.type === "all" &&
      WIDGET_IDS_WITH_FORECAST.includes(widgetId),
    [dashboardDateRange?.type, widgetId],
  );

  const isWidgetCanBeUpdatedBySP = useMemo<boolean>(
    () => availableWidgetIds.has(widgetId),
    [availableWidgetIds, widgetId],
  );

  const isWidgetCanBeUpdated = useMemo<boolean>(() => {
    if (widgetStatus === "not_calculated") return true;

    if (
      widgetStatus === "success" &&
      !isForecastCalculated &&
      isForecastAvailable
    )
      return true;

    return (
      isCompanyTrackersCollection && isWidgetCanBeUpdatedBySP && !isReadOnly
    );
  }, [
    isReadOnly,
    widgetStatus,
    isForecastAvailable,
    isForecastCalculated,
    isWidgetCanBeUpdatedBySP,
    isCompanyTrackersCollection,
  ]);

  const isFirstWidgetCalculation = useMemo<boolean>(
    () => widgetStatus === "not_calculated",
    [widgetStatus],
  );

  const hasWidgetSync = useMemo<boolean>(
    () => Boolean(widgetSync),
    [widgetSync],
  );

  const isDataUpdateReady = useMemo<boolean>(() => {
    if (!isDataUpdateAllowed || !widgetUpdatedAt) return false;

    try {
      const formattedWidgetUpdatedAt = new Date(widgetUpdatedAt);

      for (const keywordsDataSource of trackersCollectionKeywordsDataSources) {
        const keywordsDataSourceUpdatedAt =
          keywordsSettings[keywordsDataSource].dateUpdatedAt;

        const formattedKeywordsDataSourceUpdatedAt = new Date(
          keywordsDataSourceUpdatedAt,
        );

        const isKeywordsDataSourceUpdateReady = isAfter(
          formattedKeywordsDataSourceUpdatedAt,
          formattedWidgetUpdatedAt,
        );

        if (!isKeywordsDataSourceUpdateReady) return false;
      }

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }, [
    widgetUpdatedAt,
    keywordsSettings,
    isDataUpdateAllowed,
    trackersCollectionKeywordsDataSources,
  ]);

  const isTrackersCollectionUpdated = useMemo<boolean>(() => {
    if (!trackersCollectionUpdatedAt || !widgetUpdatedAt) return false;

    try {
      const [formattedTrackersCollectionUpdatedAt, formattedWidgetUpdatedAt] = [
        new Date(trackersCollectionUpdatedAt),
        new Date(widgetUpdatedAt),
      ];

      return isAfter(
        formattedTrackersCollectionUpdatedAt,
        formattedWidgetUpdatedAt,
      );
    } catch (error) {
      console.error(error);

      return false;
    }
  }, [widgetUpdatedAt, trackersCollectionUpdatedAt]);

  const isWidgetSyncUpdated = useMemo<boolean>(() => {
    if (!trackersCollectionUpdatedAt || !widgetSync?.startedAt) return false;

    try {
      const [
        formattedTrackersCollectionUpdatedAt,
        formattedWidgetSyncStartedAt,
      ] = [
        new Date(trackersCollectionUpdatedAt),
        new Date(widgetSync.startedAt),
      ];

      return isAfter(
        formattedWidgetSyncStartedAt,
        formattedTrackersCollectionUpdatedAt,
      );
    } catch (error) {
      console.error(error);

      return false;
    }
  }, [trackersCollectionUpdatedAt, widgetSync?.startedAt]);

  const dependentWidgetIds = useMemo<Widget.IdType[]>(() => {
    const widgetIds = new Set<Widget.IdType>();

    for (const widget of widgets) {
      const isWidgetDependent = widget.dependentOnWidgets.includes(widgetId);

      if (isWidgetDependent) widgetIds.add(widget.id);
    }

    return [...widgetIds];
  }, [widgetId, widgets]);

  const triggerWidgetSyncs = useCallback(
    (isScheduled: boolean): Promise<void> =>
      dispatch(
        createWidgetSyncs({
          isScheduled,
          forceUpdate: true,
          dependentWidgetIds,
          dashboardDateRangeId,
          widgetIds: [widgetId],
        }),
      )
        .unwrap()
        .catch((error) => {
          showDevelopmentError({
            error,
            additionalTexts: [
              "Error on widget calculation",
              `TrackersCollectionId: ${trackersCollectionId}`,
              `DashboardDateRangeId: ${dashboardDateRangeId}`,
              `For widgets: ${[widgetId, ...dependentWidgetIds]}`,
            ],
          });

          showToastNotification({
            type: "error",
            text: "Oops, we have problem with widgets calculation",
          });
        }),
    [
      widgetId,
      dispatch,
      dependentWidgetIds,
      dashboardDateRangeId,
      trackersCollectionId,
    ],
  );

  // Fetch widget if widgetStatus "idle"
  useEffect(() => {
    if (!dashboardDateRangeId || !trackersCollectionId) return;

    if (widgetStatus !== "idle") return;

    dispatch(
      fetchWidgets({
        dashboardDateRangeId,
        trackersCollectionId,
        widgetsIds: [widgetId],
      }),
    )
      .unwrap()
      .catch(console.error);
  }, [
    dispatch,
    widgetId,
    widgetStatus,
    trackersCollectionId,
    dashboardDateRangeId,
  ]);

  // Change widgetStatus to "idle" if sync has finished
  useEffect(() => {
    if (!dashboardDateRangeId) return;

    if (hasWidgetSync) return;

    if (widgetStatus !== "calculating" && widgetStatus !== "updating") return;

    dispatch(
      changeWidgetStatus({ widgetId, status: "idle", dashboardDateRangeId }),
    );
  }, [dashboardDateRangeId, dispatch, hasWidgetSync, widgetId, widgetStatus]);

  // Trigger widget sync if trackers collection has been updated
  useEffect(() => {
    if (!dashboardDateRangeId || !isWidgetCanBeUpdated || !widgetUpdatedAt)
      return;

    if (
      widgetStatus === "idle" ||
      widgetStatus === "loading" ||
      widgetStatus === "sync_triggered" ||
      widgetStatus === "calculating" ||
      widgetStatus === "updating"
    )
      return;

    if (!isTrackersCollectionUpdated) return;

    triggerWidgetSyncs(false).catch();
  }, [
    widgetStatus,
    widgetUpdatedAt,
    triggerWidgetSyncs,
    isWidgetCanBeUpdated,
    dashboardDateRangeId,
    isTrackersCollectionUpdated,
  ]);

  // Trigger widget sync if trackers collection has been updated and sync is in progress
  useEffect(() => {
    if (!dashboardDateRangeId || !isWidgetCanBeUpdated) return;

    if (widgetStatus !== "calculating" && widgetStatus !== "updating") return;

    if (!isTrackersCollectionUpdated) return;

    if (!hasWidgetSync || (hasWidgetSync && isWidgetSyncUpdated)) return;

    triggerWidgetSyncs(false).catch();
  }, [
    widgetStatus,
    hasWidgetSync,
    triggerWidgetSyncs,
    isWidgetSyncUpdated,
    isWidgetCanBeUpdated,
    dashboardDateRangeId,
    isTrackersCollectionUpdated,
  ]);

  // Trigger widget sync if initial calculation
  useEffect(() => {
    if (!dashboardDateRangeId || !isWidgetCanBeUpdated) return;

    if (
      widgetStatus === "idle" ||
      widgetStatus === "loading" ||
      widgetStatus === "sync_triggered" ||
      widgetStatus === "calculating" ||
      widgetStatus === "updating" ||
      widgetStatus === "success"
    )
      return;

    if (!isFirstWidgetCalculation) return;

    triggerWidgetSyncs(false).catch();
  }, [
    widgetStatus,
    triggerWidgetSyncs,
    isWidgetCanBeUpdated,
    dashboardDateRangeId,
    isFirstWidgetCalculation,
  ]);

  // Trigger widget sync if update is ready
  useEffect(() => {
    if (!dashboardDateRangeId || !isWidgetCanBeUpdated) return;

    if (
      widgetStatus === "idle" ||
      widgetStatus === "loading" ||
      widgetStatus === "sync_triggered" ||
      widgetStatus === "calculating" ||
      widgetStatus === "updating"
    )
      return;

    if (!isDataUpdateReady) return;

    triggerWidgetSyncs(true).catch();
  }, [
    widgetStatus,
    isDataUpdateReady,
    triggerWidgetSyncs,
    isWidgetCanBeUpdated,
    dashboardDateRangeId,
  ]);

  // Trigger widget sync if forecast is available and not calculated
  useEffect(() => {
    if (!dashboardDateRangeId || !isWidgetCanBeUpdated) return;

    if (widgetStatus !== "success") return;

    if (!isForecastAvailable || isForecastCalculated) return;

    triggerWidgetSyncs(false).catch();
  }, [
    widgetStatus,
    triggerWidgetSyncs,
    isForecastAvailable,
    isForecastCalculated,
    dashboardDateRangeId,
    isWidgetCanBeUpdated,
  ]);
};
