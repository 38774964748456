import {
  Draft,
  EntityState,
  createSlice,
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import omit from "lodash/omit";

import { selectTrackersById } from "../trackers/trackersSelector";
import { selectTrackersCollectionById } from "../trackersCollections/trackersCollectionsSelector";

// Inner imports
import * as api from "./widgetsApi";
import type { WidgetData } from "./types";

export const widgetsAdapter = createEntityAdapter<Widgets.Data>({
  selectId: ({ dashboardDateRangeId }) => dashboardDateRangeId,
});

const initialState = widgetsAdapter.getInitialState<Store.InitialState>({
  status: "idle",
  error: null,
});

export const fetchVolumeShareWidget = createAsyncThunk<
  Widgets.DataSources[Widget.IdType],
  {
    widgetId: Widget.IdType;
    dashboardDateRangeId: DashboardDateRange.Data["id"];
    trackersCollectionId: TrackersCollection.Data["id"];
  }
>("widgets/fetch-by-id/volume-share", api.getVolumeShareWidget);

export const fetchSearchShareWidget = createAsyncThunk<
  Widgets.DataSources[Widget.IdType],
  {
    widgetId: Widget.IdType;
    dashboardDateRangeId: DashboardDateRange.Data["id"];
    trackersCollectionId: TrackersCollection.Data["id"];
  }
>("widgets/fetch-by-id/search-share", api.getSearchShareWidget);

export const fetchArticlesWidget = createAsyncThunk<
  Widgets.DataSources[Widget.IdType],
  {
    widgetId: Widget.IdType;
    dashboardDateRangeId: DashboardDateRange.Data["id"];
    trackersCollectionId: TrackersCollection.Data["id"];
  },
  { state: Store.RootState }
>(
  "widgets/fetch-by-id/articles",
  ({ dashboardDateRangeId, trackersCollectionId, widgetId }, { getState }) => {
    const state = getState();

    const trackersCollection = selectTrackersCollectionById(
      state,
      trackersCollectionId,
    );

    const trackerIds = trackersCollection?.trackerIds || [];

    const trackers = selectTrackersById(state, trackerIds);

    return api.getArticlesWidget({
      widgetId,
      trackersCollectionId,
      dashboardDateRangeId,
      paginationProps: { trackers },
    });
  },
);

export const updateArticlesWidgetData = createAsyncThunk<
  WidgetData<"widget-articles">,
  {
    dashboardDateRangeId: DashboardDateRange.Data["id"];
    changes: Widgets.Articles.Data;
  },
  { state: Store.RootState }
>(
  "widgets/update-one/articles",
  ({ dashboardDateRangeId, changes }, { getState }) => {
    const { widgets } = getState();

    const {
      data = {},
      updatedAt = "",
      status = "data_empty",
    } = widgets.entities[dashboardDateRangeId]?.["widget-articles"] || {};

    return {
      status,
      updatedAt,
      data: { ...data, ...changes },
    };
  },
);

export const fetchTopKeywordsWidget = createAsyncThunk<
  Widgets.DataSources[Widget.IdType],
  {
    widgetId: Widget.IdType;
    dashboardDateRangeId: DashboardDateRange.Data["id"];
    trackersCollectionId: TrackersCollection.Data["id"];
  }
>("widgets/fetch-by-id/top-keywords", api.getTopKeywordsWidget);

export const fetchTrendingKeywordsWidget = createAsyncThunk<
  Widgets.DataSources[Widget.IdType],
  {
    widgetId: Widget.IdType;
    dashboardDateRangeId: DashboardDateRange.Data["id"];
    trackersCollectionId: TrackersCollection.Data["id"];
  }
>("widgets/fetch-by-id/trending-keywords", api.getTrendingKeywordsWidget);

export const fetchInsightsWidget = createAsyncThunk<
  Widgets.DataSources[Widget.IdType],
  {
    widgetId: Widget.IdType;
    dashboardDateRangeId: DashboardDateRange.Data["id"];
    trackersCollectionId: TrackersCollection.Data["id"];
  }
>("widgets/fetch-by-id/insights", api.getInsightsWidget);

export const updateInsightsWidgetData = createAsyncThunk<
  WidgetData<"widget-insights">,
  {
    dashboardDateRangeId: DashboardDateRange.Data["id"];
    changes: Widgets.Insights.Data;
  },
  { state: Store.RootState }
>(
  "widgets/update-one/insights",
  async ({ changes, dashboardDateRangeId }, { getState }) => {
    const { widgets } = getState();

    const {
      data = {},
      updatedAt = "",
      status = "data_empty",
    } = widgets.entities[dashboardDateRangeId]?.["widget-insights"] || {};

    await api.updateInsightsWidget({
      id: dashboardDateRangeId,
      changes: omit(changes, ["startDate", "endDate"]),
    });

    return {
      status,
      updatedAt,
      data: { ...data, ...changes },
    };
  },
);

export const fetchWidgets = createAsyncThunk<
  {
    widgetsIds: Widget.IdType[];
    dashboardDateRangeId: DashboardDateRange.Data["id"];
  },
  {
    widgetsIds: Widget.IdType[];
    dashboardDateRangeId: DashboardDateRange.Data["id"];
    trackersCollectionId: TrackersCollection.Data["id"];
  },
  { state: Store.RootState }
>(
  "widgets/fetch-by-dashboard-date-range-id",
  async (
    { widgetsIds, dashboardDateRangeId, trackersCollectionId },
    { dispatch },
  ) =>
    Promise.allSettled(
      widgetsIds.map((widgetId) => {
        dispatch(
          changeWidgetStatus({
            widgetId,
            status: "loading",
            dashboardDateRangeId,
          }),
        );

        switch (widgetId) {
          case "widget-volume-share":
            return dispatch(
              fetchVolumeShareWidget({
                widgetId,
                dashboardDateRangeId,
                trackersCollectionId,
              }),
            );
          case "widget-search-share":
            return dispatch(
              fetchSearchShareWidget({
                widgetId,
                dashboardDateRangeId,
                trackersCollectionId,
              }),
            );
          case "widget-articles":
            return dispatch(
              fetchArticlesWidget({
                widgetId,
                dashboardDateRangeId,
                trackersCollectionId,
              }),
            );
          case "widget-top-keywords":
            return dispatch(
              fetchTopKeywordsWidget({
                widgetId,
                dashboardDateRangeId,
                trackersCollectionId,
              }),
            );
          case "widget-trending-keywords":
            return dispatch(
              fetchTrendingKeywordsWidget({
                widgetId,
                dashboardDateRangeId,
                trackersCollectionId,
              }),
            );
          case "widget-insights":
            return dispatch(
              fetchInsightsWidget({
                widgetId,
                dashboardDateRangeId,
                trackersCollectionId,
              }),
            );
        }
      }),
    ).then(() => ({ widgetsIds, dashboardDateRangeId })),
);

export const createWidgetSyncs = createAsyncThunk<
  void,
  {
    forceUpdate: boolean;
    isScheduled: boolean;
    widgetIds: Widget.IdType[];
    dependentWidgetIds: Widget.IdType[];
    dashboardDateRangeId: DashboardDateRange.Data["id"];
  },
  { state: Store.RootState }
>("widgets/create-widget-sync", async (payload) => {
  await api.createWidgetSyncs(payload);
});

const widgetsSlice = createSlice({
  name: "widgets",
  initialState,
  reducers: {
    changeWidgetStatus(
      state,
      action: PayloadAction<{
        status: Widget.Status;
        widgetId: Widget.IdType;
        dashboardDateRangeId: DashboardDateRange.Data["id"];
      }>,
    ) {
      const { dashboardDateRangeId, widgetId, status } = action.payload;

      const widget = state.entities[dashboardDateRangeId]?.[widgetId];

      if (widget) widget.status = status;
      else
        widgetsAdapter.updateOne(state, {
          id: dashboardDateRangeId,
          changes: { [widgetId]: { status, updatedAt: "" } },
        });
    },
    changeWidgetsStatus(
      state,
      action: PayloadAction<{
        status: Widget.Status;
        widgetIds: Widget.IdType[];
        dashboardDateRangeId: DashboardDateRange.Data["id"];
      }>,
    ) {
      const { dashboardDateRangeId, widgetIds, status } = action.payload;

      for (const widgetId of widgetIds) {
        const widget = state.entities[dashboardDateRangeId]?.[widgetId];

        if (widget) widget.status = status;
        else
          widgetsAdapter.updateOne(state, {
            id: dashboardDateRangeId,
            changes: { [widgetId]: { status, updatedAt: "" } },
          });
      }
    },
    writeInitialWidgetStructure(
      state,
      action: PayloadAction<{
        widgetId: Widget.IdType;
        dashboardDateRangeId: DashboardDateRange.Data["id"];
      }>,
    ) {
      const { dashboardDateRangeId, widgetId } = action.payload;

      widgetsAdapter.updateOne(state, {
        id: dashboardDateRangeId,
        changes: { [widgetId]: { status: "idle", updatedAt: "" } },
      });
    },
    removeWidgetStructure(
      state,
      action: PayloadAction<{
        widgetId: Widget.IdType;
        dashboardDateRangeId: DashboardDateRange.Data["id"];
      }>,
    ) {
      const { dashboardDateRangeId, widgetId } = action.payload;

      const widgets = state.entities[dashboardDateRangeId];

      if (!widgets) return;

      widgetsAdapter.setOne(state, omit(widgets, [widgetId]));
    },
  },
  extraReducers: (builder) => {
    //#region VOLUME SHARE
    builder.addCase(fetchVolumeShareWidget.fulfilled, (state, action) =>
      writeWidgetData("widget-volume-share", state, action),
    );
    //#endregion VOLUME SHARE

    //#region SEARCH SHARE
    builder.addCase(fetchSearchShareWidget.fulfilled, (state, action) =>
      writeWidgetData("widget-search-share", state, action),
    );
    //#endregion SEARCH SHARE

    //#region ARTICLES
    builder.addCase(fetchArticlesWidget.fulfilled, (state, action) =>
      writeWidgetData("widget-articles", state, action),
    );

    builder.addCase(updateArticlesWidgetData.fulfilled, (state, action) =>
      updateWidgetData("widget-articles", state, action),
    );
    //#endregion ARTICLES

    //#region TOP KEYWORDS
    builder.addCase(fetchTopKeywordsWidget.fulfilled, (state, action) =>
      writeWidgetData("widget-top-keywords", state, action),
    );
    //#endregion TOP KEYWORDS

    //#region TRENDING KEYWORDS
    builder.addCase(fetchTrendingKeywordsWidget.fulfilled, (state, action) =>
      writeWidgetData("widget-trending-keywords", state, action),
    );
    //#endregion TRENDING KEYWORDS

    //#region INSIGHTS
    builder.addCase(fetchInsightsWidget.fulfilled, (state, action) =>
      writeWidgetData("widget-insights", state, action),
    );

    builder.addCase(updateInsightsWidgetData.fulfilled, (state, action) =>
      updateWidgetData("widget-insights", state, action),
    );
    //#endregion INSIGHTS
  },
});

export const {
  changeWidgetStatus,
  removeWidgetStructure,
  writeInitialWidgetStructure,
} = widgetsSlice.actions;

export default widgetsSlice.reducer;

function writeWidgetData<T extends Widget.IdType>(
  widgetId: T,
  state: Draft<EntityState<Widgets.Data> & Store.InitialState>,
  action: PayloadAction<
    Widgets.DataSources[Widget.IdType],
    string,
    {
      arg: {
        widgetId: Widget.IdType;
        dashboardDateRangeId: DashboardDateRange.Data["id"];
        trackersCollectionId: TrackersCollection.Data["id"];
      };
      requestId: string;
      requestStatus: "fulfilled";
    }
  >,
): void {
  const {
    payload: widgetData,
    meta: {
      arg: { dashboardDateRangeId },
    },
  } = action;

  const collection = state.entities[dashboardDateRangeId];

  if (collection) collection[widgetId] = widgetData as any;
  else
    widgetsAdapter.addOne(state, {
      dashboardDateRangeId,
      [widgetId]: widgetData,
    });
}

function updateWidgetData<T extends Widget.IdType>(
  widgetId: T,
  state: Draft<EntityState<Widgets.Data> & Store.InitialState>,
  action: PayloadAction<
    Widgets.DataSources[T],
    string,
    {
      arg: {
        changes: WidgetData<T>["data"];
        dashboardDateRangeId: DashboardDateRange.Data["id"];
      };
    }
  >,
): void {
  const {
    payload: widgetData,
    meta: {
      arg: { dashboardDateRangeId },
    },
  } = action;

  const collection = state.entities[dashboardDateRangeId];

  if (collection) collection[widgetId] = widgetData as any;
  else
    widgetsAdapter.updateOne(state, {
      id: dashboardDateRangeId,
      changes: { [widgetId]: widgetData },
    });
}
