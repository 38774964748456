import { FC, useMemo } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./SelectedTrackersSection.module.scss";
import { useModal } from "src/hooks";
import { Plus } from "src/assets/icons";
import { Button, Translation } from "src/components";
import { AddCustomTrackerModal } from "src/features";

// Inner imports
import { SelectedTracker } from "./components";

type Props = {
  selectedTrackers: Tracker.CreationData[];
  selectedSearches: Record<string, Search.CreationData[]>;
  unselectTrackerHandler: (tracker: Tracker.CreationData) => void;
  openTrackerSettingsHandler: (tracker: Tracker.CreationData) => void;
  selectTrackerHandler: (
    tracker: Optional<
      Tracker.CreationData,
      "locationId" | "languageId" | "keywordsDataSources"
    >,
  ) => void;
  reselectSearchesHandler: (
    tracker: Tracker.CreationData,
    searches: Search.CreationData[],
  ) => void;
  selectSearchHandler: (
    tracker: Tracker.CreationData,
    search: Search.CreationData,
  ) => void;
  updateTrackerHandler: ({
    id,
    changes,
    callback,
  }: {
    id: Tracker.CreationData["id"];
    changes: Tracker.CreationData;
    callback?: () => void;
  }) => void;
};

export const SelectedTrackersSection: FC<Props> = ({
  selectedTrackers,
  selectedSearches,
  selectSearchHandler,
  selectTrackerHandler,
  updateTrackerHandler,
  unselectTrackerHandler,
  reselectSearchesHandler,
  openTrackerSettingsHandler,
}) => {
  const { t } = useTranslation();

  const { setModal } = useModal();

  const hasSelectedTrackers = useMemo<boolean>(
    () => Boolean(selectedTrackers.length),
    [selectedTrackers.length],
  );

  const onPlaceholderClick = (): void =>
    setModal(<AddCustomTrackerModal submitHandler={selectTrackerHandler} />);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <span>
          {t("page.create_tracker.selection.label.selected_trackers")}
        </span>
      </div>
      <div
        className={cx(
          styles.selectedTrackers,
          hasSelectedTrackers ? "" : styles.selectedTrackersEmpty,
        )}
      >
        {hasSelectedTrackers && (
          <Button
            buttonStyle="transparent"
            onClick={onPlaceholderClick}
            className={styles.selectedTrackerPlaceholder}
          >
            <span>
              {t("page.create_tracker.selection.label.add_tracker_manually")}
            </span>
            <Plus />
          </Button>
        )}
        {hasSelectedTrackers ? (
          selectedTrackers.map((tracker) => {
            const selectedTrackerSearches = selectedSearches[tracker.id] || [];

            return (
              <SelectedTracker
                key={tracker.id}
                tracker={tracker}
                selectedTrackers={selectedTrackers}
                selectSearchHandler={selectSearchHandler}
                selectedSearches={selectedTrackerSearches}
                updateTrackerHandler={updateTrackerHandler}
                duplicateTrackerHandler={selectTrackerHandler}
                unselectTrackerHandler={unselectTrackerHandler}
                reselectSearchesHandler={reselectSearchesHandler}
                openTrackerSettingsHandler={openTrackerSettingsHandler}
              />
            );
          })
        ) : (
          <span className={styles.placeholder}>
            <Translation
              i18nKey="page.create_tracker.selection.label.select_suggested_tracker"
              components={{
                button: (
                  <Button
                    buttonSize="small"
                    buttonStyle="transparent"
                    onClick={onPlaceholderClick}
                  />
                ),
              }}
            />
          </span>
        )}
      </div>
    </div>
  );
};
